<template>
  <div style="position: relative;">
    <lm-header :scrollTop="scrollTop" />
    <div ref="banner" class="banner">
      <div class="layout">
        <div class="banner_text">
          <div>数字化协同方案专家</div>
          <div>卓越发展，数字驱动</div>
        </div>
        <button class="banner_button pointer" @click="() => {$router.push('/relation')}">立即咨询</button>
        <img :class="{ show: bannerShow }" class="view" src="@/assets/image/home_header_view.png">
        <img :class="{ show: bannerShow }" class="form" src="@/assets/image/home_header_form.png">
        <img :class="{ show: bannerShow }" class="triangle" src="@/assets/image/home_header_triangle.png">
        <img :class="{ show: bannerShow }" class="engineer" src="@/assets/image/home_header_engineer.png">
      </div>
    </div>
    <div class="product_view">
      <div class="top flex justify-between">
        <div class="title">系统集成, 协同智造</div>
        <div class="info">
          <span>仓储物流、供应商协同、库存管理、质量管理、物流执行等管理模块产品集成，</span>
          <span>为企业打造一个扎实、可靠、全面、好操作的一体化协同管理平台。</span>
        </div>
      </div>
      <div class="content">
        <div class="pointer" v-for="(item, index) in productList" :key="'product'+index" @click="toProduct(item.info)">
          <img :src="item.img" />
          <div class="title">{{ item.title }}</div>
          <div class="info">{{ item.info }}</div>
        </div>
      </div>
    </div>
    <div ref="app_view_box" class="app_view">
      <div class="title">多端应用，操作灵活</div>
      <div class="info">系统可通过手机APP、微信小程序等移动端使用，实时数据查询，信息处理等业务，实现各流程查看及审批的及时性。</div>
      <div ref="app_view" class="proview">
        <img class="app" :class="{ show: appShow }" src="@/assets/image/app.png">
        <img class="pc" :class="{ show: appShow }" src="@/assets/image/pc.png">
      </div>
    </div>
    <div ref="dataCard_box" class="data">
      <div class="title">数据驱动，智能决策</div>
      <div class="info">
        集成掌握设备利用率、故障率、KPI指标等大量关键数据，<br> 从多角度进行统计分析和对比并形成相应管理报表,提供可靠的辅助决策数据分析。
      </div>
      <div ref="dataCard" class="cardBox">
        <div v-for="(item,index) in cardData" :key="'card' + index" class="card">
          <div v-for="(data, idx) in item" :key="'data' + idx" >
            <countTo :customClass="{countTo: true}" suffix="%" :startVal="0" :endVal="data.progress" :duration="1000" :suffixStyle="{fontSize: '22px', fontWeight: 'bold' }" />
            <div class="progress" :style="{ background: data.color, width: `${data.progress}%` }"></div>
            <div class="info">{{ data.info }}</div>
          </div>
        </div>
      </div>
    </div>
    <div ref="app_view2_box" class="app_view2">
      <div class="title">全程管理，及时响应</div>
      <div class="info">
        <div>直观展示当前生产进度，了解车间各类生产异常，直观展示整体生产现状。</div>
        <div>全流程、全生命周期监控生产，对生产过程中出现的问题及时反馈升级和处理，快速提高生产协作效率。</div>
      </div>
      <div class="view" ref="app_view2">
        <img class="view" :class="{ show: app_view2Show }" src="@/assets/image/home_appView2_view.png">
        <img class="pie" :class="{ show: app_view2Show }" src="@/assets/image/home_appView2_pie.png">
        <img class="bar" :class="{ show: app_view2Show }" src="@/assets/image/home_appView2_bar.png">
      </div>
    </div>
    <div class="program">
      <div class="top flex justify-between flex-wrap">
        <div class="title">海内外客户选择的数字化⽅案</div>
        <div class="buttonBox flex ">
          <div @click="() => { swiper1.slidePrev() }">
            <svg t="1636098415988" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1313" width="12" height="12" fill=""><path d="M671.968176 911.99957c-12.287381 0-24.576482-4.67206-33.951566-14.047144L286.048434 545.984249c-18.751888-18.719204-18.751888-49.12028 0-67.872168L638.016611 126.111222c18.751888-18.751888 49.12028-18.751888 67.872168 0 18.751888 18.719204 18.751888 49.12028 0 67.872168l-318.016611 318.047574L705.888778 830.047574c18.751888 18.751888 18.751888 49.12028 0 67.872168C696.544658 907.32751 684.255557 911.99957 671.968176 911.99957z" p-id="1314"></path></svg>
          </div>
          <div @click="() => { swiper1.slideNext() }">
            <svg t="1636098924307" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2107" width="12" height="12"><path d="M761.055557 532.128047c0.512619-0.992555 1.343475-1.823411 1.792447-2.848649 8.800538-18.304636 5.919204-40.703346-9.664077-55.424808L399.935923 139.743798c-19.264507-18.208305-49.631179-17.344765-67.872168 1.888778-18.208305 19.264507-17.375729 49.631179 1.888778 67.872168l316.960409 299.839269L335.199677 813.631716c-19.071845 18.399247-19.648112 48.767639-1.247144 67.872168 9.407768 9.791372 21.984142 14.688778 34.560516 14.688778 12.000108 0 24.000215-4.479398 33.311652-13.439914l350.048434-337.375729c0.672598-0.672598 0.927187-1.599785 1.599785-2.303346 0.512619-0.479935 1.056202-0.832576 1.567101-1.343475C757.759656 538.879828 759.199462 535.391265 761.055557 532.128047z" p-id="2108"></path></svg>
          </div>
        </div>
      </div>
      <div ref="swiper" class="swiper_view">
        <div id="swiper1" class="swiper-container">
          <div class="swiper-wrapper">
            <div class="swiper-slide card">
              <div class="content">
                <div class="img">
                  <img src="./assets/英纳法2@2x.png" >
                </div>
                <div class="info">灵妙打造的数字化系统实现了生产、质量、维修等多部门的数据协同，提高了公司的生产效率，能够快速掌握车间情况，生产状况，帮助我们快速决策。</div>
                <div class="company">英纳法</div>
              </div>
            </div>
            <div class="swiper-slide card">
              <div class="content">
                <div class="img">
                  <img src="./assets/南瑞继保2@2x.png" >
                </div>
                <div class="info">用灵妙替换了之前老的MES系统，不仅为我们提供专业的数字化咨询，还定制了灵活的产品构架和完善的功能，充分匹配了公司目前的生产管理，同时还优化了许多问题审核、故障报修等流程问题，帮助我们节省了大量人工实际，提升了问题处理效率。</div>
                <div class="company">南瑞继保</div>
              </div>
            </div>
            <div class="swiper-slide card">
              <div class="content">
                <div class="img">
                  <img src="./assets/西子电梯2@2x.png" >
                </div>
                <div class="info">灵妙设计的设备管理系统能在线管理设备信息，快速记录实际生产维护数据，灵活配置维保计划，并及时推送维护任务，极大缩短了停线时间，提高了我们设备运转效率。</div>
                <div class="company">西子电梯</div>
              </div>
            </div>
            <div class="swiper-slide card">
              <div class="content">
                <div class="img">
                  <img src="./assets/科陆电子2@2x.png" >
                </div>
                <div class="info">自从使用了灵妙的数字化系统以后，以往车间内很多难以收集、分析的数据，现在都能通过系统协同聚合，可视化展示，帮助我们更好地发现生产问题，及时处理！  </div>
                <div class="company">科陆电子</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="logo_swiper">
      <div class="left"></div>
      <div class="right"></div>
      <div ref="logoList" class="container">
        <div class="wrapper">
          <div ref="logoLi" class="logoLi">
            <img src="./assets/科陆电子1@2x.png">
          </div>
          <div class="logoLi">
            <img src="./assets/英纳法1@2x.png">
          </div>
          <div class="logoLi">
            <img src="./assets/ABB1@2x.png">
          </div>
          <div class="logoLi">
            <img src="./assets/飞利浦1@2x.png">
          </div>
          <div class="logoLi">
            <img src="./assets/西子电梯1@2x.png">
          </div>
          <div class="logoLi">
            <img src="./assets/南瑞继保1@2x.png">
          </div>
          <div class="logoLi">
            <img src="./assets/吉利汽车1@2x.png">
          </div>
          <div class="logoLi">
            <img src="./assets/海纳川1@2x.png">
          </div>
          <div class="logoLi">
            <img src="./assets/新华昌1@2x.png">
          </div>
          <div class="logoLi">
            <img src="./assets/联合科技1@2x.png">
          </div>
          <div class="logoLi">
            <img src="./assets/华胤钢构1@2x.png">
          </div>
        </div>
      </div>
    </div>
    <lm-footer />
    <img class="bj1" src="@/assets/image/home_bigBj_1.png">
    <img class="bj2" src="@/assets/image/home_bigBj_2.png">
  </div>
</template>

<script>
  import LmHeader from '@/components/header';
  import LmFooter from '@/components/footer';
  import wms from '@/assets/image/wms_icon.png';
  import qms from '@/assets/image/qms_icon.png';
  import mes from '@/assets/image/mes_icon.png';
  import srm from '@/assets/image/srm_icon.png';
  import les from '@/assets/image/les_icon.png';
  import CountTo from '@/components/countTo';
  import Swiper from 'swiper';
  import anime from 'animejs';
  export default {
    components: {
      LmHeader,
      LmFooter,
      CountTo
    },
    data() {
      return {
        productList: [
          {
            img: mes,
            title: '制造执行系统',
            info: 'MES'
          },
          {
            img: srm,
            title: '供应商协同系统',
            info: 'SRM'
          },
          {
            img: les,
            title: '物流执行系统',
            info: 'LES'
          },
          {
            img: wms,
            title: '仓库管理系统',
            info: 'WMS'
          },
          {
            img: qms,
            title: '质量管理系统',
            info: 'QMS'
          }
        ],
        scrollTop: 0,
        bannerShow: false,
        appShow: true,
        app_view2Show: true,
        dataShow: false,
        bannerH: 0,
        app_viewTop: 0,
        app_viewBottom: 0,
        dataCardTop: 0,
        dataCardBottom: 0,
        app_view2Top: 0,
        app_view2Bottom: 0,
        cardData: [
          [
            {
              info: '生产成本降低',
              progress: 28,
              color: '#F16994'
            },
            {
              info: '反应时间缩短',
              progress: 26,
              color: '#A77EED'
            },
            {
              info: '产品质量提升',
              progress: 38,
              color: '#FF9000'
            },
          ],
          [
            {
              info: '人员效率提升',
              progress: 42,
              color: '#67BD72'
            },
            {
              info: '问题反应速度提升',
              progress: 40,
              color: '#2EAFE2'
            },
            {
              info: '数据展示增加',
              progress: 41,
              color: '#8B66C0'
            },
          ],
          [
            {
              info: '设备效率提升',
              progress: 32,
              color: '#FF9000'
            },
            {
              info: '数据汇总和分析时间减低',
              progress: 60,
              color: '#67BD72'
            },
            {
              info: '降低原料库存',
              progress: 25,
              color: '#2EAFE2'
            },
          ]
        ],
        swiper1: null,
        swiper2: null,
        timeout: null
      }
    },
    mounted () {
      setTimeout(() => {
        this.bannerShow = true;
      }, 100);
      this.init();
      window.onresize = () => {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          this.init();
        }, 1000);
      };
    },
    destroyed () {
      window.removeEventListener('scroll', this.getScroll)
    },
    methods: {
      init() {
        console.log('init')
        const vh = window.innerHeight;
        
        this.bannerH = this.$refs.banner.offsetHeight;

        this.app_viewTop = this.$refs.app_view.offsetTop + this.$refs.app_view_box.offsetTop - vh + 200;
        this.app_viewBottom = this.$refs.app_view.offsetTop + this.$refs.app_view_box.offsetTop + this.$refs.app_view.offsetHeight - 200;
        
        this.dataCardTop = this.$refs.dataCard.offsetTop + this.$refs.dataCard_box.offsetTop - vh + 100;
        this.dataCardBottom = this.$refs.dataCard.offsetTop + this.$refs.dataCard_box.offsetTop + this.$refs.dataCard.offsetHeight - 100;
        
        this.app_view2Top = this.$refs.app_view2.offsetTop + this.$refs.app_view2_box.offsetTop - vh + 200;
        this.app_view2Bottom = this.$refs.app_view2.offsetTop + this.$refs.app_view2_box.offsetTop + this.$refs.app_view2.offsetHeight - 300;
        
        window.addEventListener('scroll', this.getScroll);
        this.swiper1 = new Swiper ('#swiper1', {
          loop: true,
          slidesPerView: 3,
          autoplay: {
            delay: 5000,//时间 毫秒
            disableOnInteraction: false //用户操作之后是否停止自动轮播默认true
          },
        })
        const logoLiWidth = (this.$refs.logoLi.clientWidth + (this.$refs.logoLi.clientWidth / 190 * 11));
        const xTrans =  [];
        anime.set('.logoLi',{
          translateX: function(el, i) {
            xTrans[i]={x : i * logoLiWidth };
            return i * logoLiWidth ;
          },
        });
        anime({
          targets: xTrans,
          duration: 70000, //走一周持续时间
          easing: 'linear',
          x: `+=${logoLiWidth * 11}`,
          loop: true,
          update: function() {
              anime.set('.logoLi', {
                translateX:function(el, i){
                    return xTrans[i].x%(logoLiWidth * 11)
                  }
              });
          }
        })
        this.getScroll();
      },
      getScroll() {
        this.scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
        if (this.scrollTop > this.bannerH && this.bannerShow) this.bannerShow = false;
        else if (this.scrollTop < this.bannerH && !this.bannerShow) this.bannerShow = true;

        if (this.scrollTop >= this.app_viewTop && this.scrollTop <= this.app_viewBottom) {
          if (!this.appShow) this.appShow = true;
        } else {
          if (this.appShow) this.appShow = false;
        }

        if (this.scrollTop >= this.dataCardTop && this.scrollTop <= this.dataCardBottom) {
          if (!this.dataShow) {
            this.dataShow = true;
            this.$nextTick(() => {
              this.cardData = [
                [
                  {
                    info: '生产成本降低',
                    progress: 28,
                    color: '#F16994'
                  },
                  {
                    info: '反应时间缩短',
                    progress: 26,
                    color: '#A77EED'
                  },
                  {
                    info: '产品质量提升',
                    progress: 38,
                    color: '#FF9000'
                  },
                ],
                [
                  {
                    info: '人员效率提升',
                    progress: 42,
                    color: '#67BD72'
                  },
                  {
                    info: '问题反应速度提升',
                    progress: 40,
                    color: '#2EAFE2'
                  },
                  {
                    info: '数据展示增加',
                    progress: 41,
                    color: '#8B66C0'
                  },
                ],
                [
                  {
                    info: '设备效率提升',
                    progress: 32,
                    color: '#FF9000'
                  },
                  {
                    info: '数据汇总和分析时间减低',
                    progress: 60,
                    color: '#67BD72'
                  },
                  {
                    info: '降低原料库存',
                    progress: 25,
                    color: '#2EAFE2'
                  },
                ]
              ];
            })
          }
        } else {
          if (this.dataShow) {
            this.dataShow = false;
            this.cardData = [
              [
                {
                  info: '生产成本降低',
                  progress: 0,
                  color: '#F16994'
                },
                {
                  info: '反应时间缩短',
                  progress: 0,
                  color: '#A77EED'
                },
                {
                  info: '产品质量提升',
                  progress: 0,
                  color: '#FF9000'
                },
              ],
              [
                {
                  info: '人员效率提升',
                  progress: 0,
                  color: '#67BD72'
                },
                {
                  info: '问题反应速度提升',
                  progress: 0,
                  color: '#2EAFE2'
                },
                {
                  info: '数据展示增加',
                  progress: 0,
                  color: '#8B66C0'
                },
              ],
              [
                {
                  info: '设备效率提升',
                  progress: 0,
                  color: '#FF9000'
                },
                {
                  info: '数据汇总和分析时间减低',
                  progress: 0,
                  color: '#67BD72'
                },
                {
                  info: '降低原料库存',
                  progress: 0,
                  color: '#2EAFE2'
                },
              ]
            ];
          }
        }

        if (this.scrollTop >= this.app_view2Top && this.scrollTop <= this.app_view2Bottom) {
          if (!this.app_view2Show) this.app_view2Show = true;
        } else {
          if (this.app_view2Show) this.app_view2Show = false;
        }
      
      },
      toProduct(type) {
        this.$router.push({
          name: 'product',
          params: {
            type
          }
        })
      }
    }
  }
</script>

<style lang="less" scoped>
  .banner {
    margin: 0 auto;
    width: 1920px;
    height: 610px;
    background: url('../../assets/image/headerBj.png') no-repeat;
    background-size: 1920px;
    background-position-y: -30px;
    .layout {
      height: 610px;
      position: relative;
      img {
        position: absolute;
      }
      .view {
        width: 619px;
        bottom: -30px;
        right: 0px;
        z-index: 1;
        opacity: 0;
        // transition: all 0.5s linear 0.75s;
        &.show {
          transition: all 0.75s;
          opacity: 1;
          bottom: 0px;
        }
      }
      .form {
        width: 247px;
        bottom: 0px;
        right: 468px;
        z-index: 2;
        opacity: 0;
        // transition: all 0.5s linear 0.5s;
        &.show {
          transition: all 0.5s linear .25s;
          opacity: 1;
          right: 438px;
        }
      }
      .triangle {
        width: 205px;
        bottom: 308px;
        right: 160px;
        z-index: 2;
        opacity: 0;
        // transition: all 0.5s linear .25s;
        &.show {
          bottom: 278px;
          opacity: 1;
          transition: all 0.5s linear 0.5s;
        }
      }
      .engineer {
        width: 210px;
        bottom: 0px;
        right: -30px;
        z-index: 2;
        opacity: 0;
        // transition: all 0.5s;
        &.show {
          transition: all 0.5s linear 0.75s;
          right: 0px;
          opacity: 1;
        }
      }
      .banner_text {
        position: absolute;
        left: 0px;
        top: 213px;
        font-size: 50px;
        line-height: 80px;
        text-align: left;
        color: white;
      }
      .banner_button {
        position: absolute;
        bottom: 132px;
        left: 0;
        width: 180px;
        height: 56px;
        border: 0;
        border-radius: 4px;
        background: #FF9000;
        font-size: 22px;
        color: white;
      }
    }
  }
  .product_view {
    width: 1240px;
    margin: 0 auto;
    .top {
      padding-top: 76px;
      .title {
        font-size: 44px;
        font-weight: 400;
        color: #333333;
        line-height: 40px;
        text-align: left;
        white-space: nowrap;
      }
      .info {
        padding-left: 190px;
        font-size: 18px;
        color: #666666;
        line-height: 40px;
        text-align: left;
        span {
          display: inline-block;
        }
      }
    }
    .content {
      margin-top: 78px;
      display: flex;
      justify-content: center;
      &>div {
        width: 250px;
        height: 250px;
        background: white;
        position: relative;
        transition: all 0.5s;
        &>img {
          width: 76px;
          margin: 47px 0 35px;
        }
        .title {
          font-size: 20px;
          color: #293957;
        }
        .info {
          font-size: 20px;
          color: #91A1B1;
        }
        &::before {
          position: absolute;
          top: 0;
          left: 0;
          width: 246px;
          height: 246px;
          content: '';
          border: 2px solid #E7F0F6;
        }
      }
      &>div:hover {
        box-shadow: 0px 8px 50px 0px rgba(142, 171, 201, 0.36);
        z-index: 10;
        &::before {
          border-color: #DAE4ED;
        }
      }
    }
  }
  .app_view {
    margin: 140px auto 0;
    width: 1240px;
    position: relative;
    text-align: center;
    line-height: 1;
    z-index: 1;
    .title {
      font-size: 42px;
    }
    .info {
      padding-top: 30px;
      font-size: 18px;
      color: #666666;
    }
    .proview {
      height: 718px;
      position: relative;
      .pc {
        position: absolute;
        width: 1052px;
        left: 212px;
        top: 0;
        z-index: 1;
        opacity: 0;
        &.show {
          opacity: 1;
          left: 152px;
          transition: all 0.5s;
        }
      }
      .app {
        width: 415px;
        position: absolute;
        left: 0px;
        bottom: 0;
        z-index: 2;
        opacity: 0;
        &.show {
          opacity: 1;
          left: 54px;
          transition: all 0.5s linear 0.25s;
        }
      }
    }
  }
  .data {
    width: 1240px;
    margin: 9px auto 0;
    line-height: 1;
    position: relative;
    z-index: 1;
    text-align: center;
    .title {
      font-size: 42px;
    }
    .info {
      margin: 20px auto 0;
      width: 800px;
      font-size: 18px;
      color: #666666;
      line-height: 40px;
    }
    .cardBox {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      margin-top: 100px;
      .card {
        margin: 0 60px;
        padding: 30px 24px 30px;
        width: 240px;
        height: 410px;
        box-sizing: border-box;
        border-radius: 30px;
        box-shadow: 0px 15px 120px 0px rgba(142, 171, 201, 0.36);
        background: white;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        &>div {
          text-align: left;
        }
        .countTo {
          color: #293957;
          font-size: 46px;
          line-height: 1;
          font-weight: bold;
          font-family: DINPro-Medium;
        }
        .progress {
          margin: 0 2px;
          height: 6px;
          width: 0px;
          border-radius: 3px;
          background: white;
          transition: all 1s;
        }
        .info {
          margin-top: 14px;
          font-size: 18px;
          color: #293957;
          line-height: 1;
        }
      }
    }
  }
  .app_view2 {
    width: 1240px;
    position: relative;
    z-index: 1;
    margin: 140px auto 0;
    text-align: center;
    .title {
      font-size: 42px;
      line-height: 1;
    }
    .info {
      margin-top: 25px;
      font-size: 18px;
      color: #666666;
      line-height: 2;
    }
    &>.view {
      width: 1240px;
      height: 722px;
      position: relative;
      z-index: 1;
      .view {
        width: 1059px;
        position: absolute;
        z-index: 1;
        top: -30px;
        left: 91px;
        opacity: 0;
        &.show {
          top: 0px;
          opacity: 1;
          transition: all .5s;
        }
      }
      .bar {
        width: 489px;
        position: absolute;
        z-index: 2;
        left: -26px;
        top: 265px;
        opacity: 0;
        &.show {
          left: 14px;
          opacity: 1;
          transition: all .5s ease .25s;
        }
      }
      .pie {
        width: 462px;
        position: absolute;
        z-index: 2;
        top: 57px;
        right: 18px;
        opacity: 0;
        &.show {
          right: 48px;
          opacity: 1;
          transition: all .5s ease .5s;
        }
      }
    }
  }
  .program {
    position: relative;
    z-index: 1;
    width: 1240px;
    margin: 0 auto;
    .title {
      font-size: 42px;
    }
    .buttonBox {
      &>div {
        position: relative;
        width: 66px;
        height: 66px;
        transition: all .5s;
        .icon {
          width: 28px;
          height: 66px;
          fill: #A1B4C8;
          transition: all .5s;
        }
      }
      &>div::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        padding: 32px;
        border: 2px solid #E7F0F6;
        transition: all .5s;
      }
      &>div:hover {
        .icon {
          fill: #293957;
        }
      }
      &>div:hover::before {
        z-index: 1;
        border: 2px solid #DAE4ED;
      }
    }
    .swiper_view {
      width: 1240px;
      margin: 0 auto;
      overflow: hidden;
        margin-top: 70px;
      box-shadow: 0px 15px 100px 10px rgba(227, 242, 255, 0.75);
      .card {
        width: 400px;
        height: 270px;
        position: relative;
        background: transparent;
        .content {
          width: 400px;
          height: 230px;
          box-sizing: border-box;
          position: absolute;
          bottom: 0;
          left: 0;
          z-index: 2;
          background: white;
          padding: 58px 22px 22px;
          box-shadow: 0px 15px 100px 0px rgba(227, 242, 255, 0.75);
          .img {
            width: 72px;
            height: 72px;
            overflow: hidden;
            border-radius: 50%;
            position: absolute;
            left: 24px;
            top: -34px;
            background: #FFFFFF;
            img {
              width: 72px;
              height: 72px;
              object-fit: scale-down;
            }
          }
          .info {
            color: #293957;
            font-size: 16px;
            text-align: left;
            display: -webkit-box;
            text-overflow:ellipsis;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 4;
            line-height: 1.7;
            overflow: hidden;
          }
          .company {
            width: 100px;
            text-align: right;
            position: absolute;
            right: 21px;
            bottom: 23px;
            font-size: 16px;
            color: #7786A2;
          }
        }
      }
    }
  }
  .logo_swiper {
    width: 1442px;
    height: 170px;
    margin: 0 auto;
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    .container {
      overflow: visible;
      height: 70px;
    }
    .wrapper {
      position: relative;
      left: -210px;
    }
    .logoLi {
      width: 190px;
      height: 79px;
      margin: 0 10px;
      position: absolute;
      border-radius: 39px;
      background: white;
      box-shadow: 0px 8px 50px 0px rgba(142, 171, 201, 0.36);
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 131px;
      }
    }
    .left {
      position: absolute;
      top: 0;
      left: 0;
      width: 60px;
      height: 170px;
      z-index: 2;
      background: linear-gradient(to right, white, rgba(255,255,255, 0));
    }
    .right {
      position: absolute;
      top: 0;
      right: 0;
      width: 60px;
      height: 170px;
      z-index: 2;
      background: linear-gradient(to left, white, rgba(255,255,255, 0));
    }
  }
  .bj1 {
    width: 1920px;
    position: absolute;
    top: 1608px;
    left: 0;
  }
  .bj2 {
    width: 1920px;
    position: absolute;
    top: 3288px;
    left: 0;
  }
</style>