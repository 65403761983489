<template>
  <div class="page">
    <div class="top">
      <div class="footer_bj">
        <div>开启您的数字化智慧之旅</div>
      </div>
      <div class="btn" @click="$router.push('/relation')">免费咨询您的专属解决方案</div>
    </div>
    <div class="bottom">
      <div class="content">
        <div class="left">
          <div class="phone">
            <img src="./assets/phone.png">
            <span>400-880-9378</span>
          </div>
          <div class="email">
            <img src="./assets/email.png">
            <a href="mailto:service@lingmiaotech.com" >service@lingmiaotech.com</a>
          </div>
          <div class="location">
            <img src="./assets/location.png">
            <span >浙江省杭州市滨江区长河路351号3号楼C601室</span>
          </div>
          <div class="qrCode">
            <img src="./assets/qrcode.jpg">
            <div>-  关注我们  -</div>
          </div>
        </div>
        <div class="right">
          <div>
            <div class="title">产品</div>
            <div>
              <router-link tag="a" :to="{ name: 'product', params: { type: 'MES' } }" >制造执行系统MES</router-link>
              <router-link tag="a" :to="{ name: 'product', params: { type: 'SRM' } }" >供应链关系管理系统SRM</router-link>
              <router-link tag="a" :to="{ name: 'product', params: { type: 'LES' } }" >物流执行系统LES</router-link>
              <router-link tag="a" :to="{ name: 'product', params: { type: 'WMS' } }" >仓库管理系统WMS</router-link>
              <router-link tag="a" :to="{ name: 'product', params: { type: 'QMS' } }" >质量管理系统QMS</router-link>
            </div>
          </div>
          <div>
            <div class="title">解决方案</div>
            <div>
              <router-link tag="a" :to="{ name: 'solution', params: { index: 0 } }" >汽车零部件制造行业</router-link>
              <router-link tag="a" :to="{ name: 'solution', params: { index: 1 } }" >电子组装行业</router-link>
              <router-link tag="a" :to="{ name: 'solution', params: { index: 2 } }" >机械工程行业</router-link>
              <router-link tag="a" :to="{ name: 'solution', params: { index: 3 } }" >轻工纺织行业</router-link>
              <router-link tag="a" :to="{ name: 'solution', params: { index: 4 } }" >数字化转型&定制开发</router-link>
            </div>
          </div>
          <div>
            <div class="title">知识学院</div>
            <div>
              <router-link tag="a" :to="{ name: 'academy', params: { index: 0 } }" >数字化系统学院</router-link>
              <router-link tag="a" :to="{ name: 'academy', params: { index: 1 } }" >行业发展学院</router-link>
            </div>
          </div>
          <div>
            <div class="title">关于灵妙</div>
            <div>
              <router-link tag="a" :to="{ name: 'about', params: { index: 0 } }" >公司简介</router-link>
              <router-link tag="a" :to="{ name: 'about', params: { index: 1 } }" >招贤纳士</router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="info">
        杭州灵妙科技有限公司  版权所有  浙 ICP 备 2020038810 号
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    
  }
</script>

<style lang="less" scoped>
  .page {
    width: 1920px;
    .top {
      width: 1237px;
      height: 209px;
      position: relative;
      margin: 0 auto;
      padding-bottom: 51px;
      .footer_bj {
        width: 1237px;
        height: 209px;
        background-image: url('./assets/bj.png');
        background-repeat: no-repeat;
        background-size: 1237px;
        padding-top: 59px;
        font-size: 40px;
        font-weight: bold;
        color: #ffffff;
        line-height: 1;
      }
      .btn {
        position: absolute;
        left: 412px;
        top: 148px;
        width: 400px;
        height: 61px;
        line-height: 61px;
        text-align: center;
        background: #FF9000;
        font-size: 24px;
        color: white;
        cursor: pointer;
        box-shadow: 0px 10px 30px 0px rgba(255, 144, 0, 0.3);
        border-radius: 4px;
      }
    }
    .bottom {
      background: #F3F8FC;
      box-shadow: 0px -1px 0px 0px #D6E2EA;
      .content {
        margin: 0 auto;
        width: 1240px;
        padding: 35px 1px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .left {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 239px;
          .phone {
            font-size: 26px;
            line-height: 28px;
            font-weight: 500;
            display: flex;
            align-items: center;
            font-family: DINPro-Medium;
            img {
              width: 20px;
              margin-right: 19px;
            }
          }
          .email {
            font-size: 14px;
            color: #666666;
            display: flex;
            align-items: center;
            img {
              width: 20px;
              margin-right: 19px;
            }
          }
          .location {
            font-size: 14px;
            color: #666666;
            display: flex;
            align-items: center;
            img {
              width: 20px;
              margin-right: 19px;
            }
          }
          .qrCode {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 96px;
            font-size: 12px;
            color: #999999;
            img {
              width: 96px;
              height: 96px;
              margin-bottom: 7px;
            }
          }
        }
        .right {
          display: flex;
          justify-content: space-between;
          width: 713px;
          font-size: 14px;
          color: #666666;
          text-align: left;
          line-height: 2;
          &>div {
            .title {
              font-size: 20px;
              color: #333333;
              margin-bottom: 15px;
              line-height: 1;
            }
            a {
              display: block;
              color: #666666;
              cursor: pointer;
              text-decoration: none;
            }
            a:hover {
              color: #3A9EFF;
            }
          }
        }
      }
        .info {
          width: 1240px;
          height: 66px;
          margin: 0 auto;
          box-shadow: 0px -1px 0px 0px #D6E2EA;
          line-height: 66px;
          font-size: 14px;
          color: #999999;
        }
    }
  }
</style>