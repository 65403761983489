import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import "./assets/css/common.less";
import "amfe-flexible/index.js";
import "swiper/dist/css/swiper.css";
import VueAwesomeSwiper from "vue-awesome-swiper";

Vue.config.productionTip = false;

Vue.use(VueAwesomeSwiper);

new Vue({
	router,
	render: (h) => h(App),
}).$mount("#app");
