import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/home/index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/product',
    name: 'product',
    component: () => import('@/views/product')
  },
  {
    path: '/solution',
    name: 'solution',
    component: () => import('@/views/solution')
  },
  {
    path: '/product',
    name: 'product',
    component: () => import('@/views/product')
  },
  {
    path: '/case',
    name: 'case',
    component: () => import('@/views/case')
  },
  {
    path: '/service',
    name: 'service',
    component: () => import('@/views/service')
  },
  {
    path: '/academy',
    name: 'academy',
    component: () => import('@/views/academy')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('@/views/about')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login')
  },
  {
    path: '/relation',
    name: 'relation',
    component: () => import('@/views/relation')
  },
  {
    path: '/transfers',
    name: 'transfers',
    component: () => import('@/views/transfers')
  }
]
const router = new VueRouter({
  routes,
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
})

export default router
