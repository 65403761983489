<template>
  <div class="header flex" :style="{ backgroundColor: scrollTop ? '#ffffff' : 'transparent', boxShadow: scrollTop ? '0px 6px 20px 0px rgba(142, 171, 201, 0.12)' : '', top, transition: `all ${ top ? '0' : '0.5' }s` }">
    <div class="layout flex justify-between align-center">
      <div class="flex justify-between align-center">
        <img class="logo" @click="toHome" :src="scrollTop ? logo2 : logo1">
        <div class="flex align-center routerLinkList" style="margin-left:114px;">
          <router-link tag="div" :class="{active: active === 'home', block: scrollTop}" to="/">首页</router-link>
          <router-link tag="div" :class="{active: active === 'product', block: scrollTop}" to="/product">产品</router-link>
          <router-link tag="div" :class="{active: active === 'solution', block: scrollTop}" to="/solution">解决方案</router-link>
          <router-link tag="div" :class="{active: active === 'case', block: scrollTop}" to="/case">案例</router-link>
          <router-link tag="div" :class="{active: active === 'service', block: scrollTop}" to="/service">客户服务</router-link>
          <router-link tag="div" :class="{active: active === 'academy', block: scrollTop}" to="/academy">知识学院</router-link>
          <router-link tag="div" :class="{active: active === 'about', block: scrollTop}" to="/about">关于灵妙</router-link>
        </div>
      </div>
      <div class="flex">
        <router-link tag="div" to="/relation" class="button pointer" :style="loginStyle">登录</router-link>
        <router-link tag="div" to="/relation" class="button pointer" :style="registerStyle">注册</router-link>
      </div>
    </div>
  </div>
</template>

<script>
  import logo1 from './assets/logo.png';
  import logo2 from './assets/logo_block.png';
  export default {
    name: 'LmHeader',
    props: {
      scrollTop: {
        type: Number,
        default: () => 0
      },
      bannerH: {
        type: Number,
        default: () => 0
      },
      navH: {
        type: Number,
        default: () => 0
      },
      replaceHeader: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      active() {
        return this.$route.name
      },
      loginStyle() {
        if(this.scrollTop) {
          return {
            color: '#333333'
          }
        } else {
          return {
            color: '#FFFFFF'
          }
        }
      },
      registerStyle() {
        if(this.scrollTop) {
          return {
            backgroundColor: '#3A9EFF',
            color: '#FFFFFF'
          }
        } else {
          return {
            backgroundColor: '#FFFFFF',
            color: '#3A9EFF'
          }
        }
      },
      top() {
        if (!this.replaceHeader) return 0
        if (this.scrollTop - this.bannerH + this.navH <= 0) {
          return 0
        } else {
          return '-' + (this.scrollTop - this.bannerH + this.navH) + 'px'
        }
      }
    },
    data() {
      return {
        logo1,
        logo2,
      }
    },
    methods: {
      toHome() {
        if(this.$route.path != '/') {
          this.$router.push('/')
        } else {
          document.documentElement.scrollTop = 0;
          document.body.scrollTop = 0;
        }
      }
    }
  }
</script>

<style lang="less" scoped>
  .header {
    width: 1920px;
    position: fixed;
    height: 80px;
    top: 0;
    left: 0;
    z-index: 999;
    .logo {
      width: 180px;
      cursor: pointer;
    }
    .routerLinkList {
      font-size: 16px;
      transition: all .5s;
      color: @lm-text-color-inverse;
      cursor: default;
      .block {
        color: #333333;
        opacity: 1;
      }
      .block.active {
        color: #3A9EFF;
      }
      .block:hover {
        color: #3A9EFF;
      }
      div {
        transition: all .5s;
        padding: 0 20px;
        opacity: 0.66;
        line-height: 80px;
        cursor: pointer;
      }
      div:hover {
        opacity: 1;
      }
      div.active {
        opacity: 1;
      }
    }
    .button {
      padding: 10px 24px;
      font-size: 16px;
      line-height: 1;
      color: #FFFFFF;
      border-radius: 4px;
      margin-left: 10px;
      cursor: pointer;
    }
  }
</style>